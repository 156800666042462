<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-2" @click="informationernaehrungzuinformation()">
            <img src="../assets/icons/vector_back.svg">
            <!-- wenn kein Icon, img entfernen -->
          </div>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-white">Komplikationen</p>
          </div>
          <div class="col-2 col-right">
            <!-- wenn kein Icon, img entfernen -->
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-body margintop140 container marginbottom20">
      <p class="pregular fontcolor-white opacity80">Risikofaktoren, denen mit richtiger Prävention weitestgehend vorgesorgt werden kann:</p>
    </div>

    <div class="container paddingbottom110">
      <div class="accordion" role="tablist">
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info">
              <p class="psemibold">
                Abstoßung
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Trotz der hochwirksamen Medikamente, die Sie zur Verhinderung von Abstoßungen einnehmen, kann es dennoch zu einer Abstoßungsreaktion kommen. Unter eine Abstoßung versteht man die Abwehrreaktion des Körpers gegen das transplantierte Organ. Die Wahrscheinlichkeit für eine akute Abstoßung ist in den ersten drei bis sechs Wochen am höchsten und sinkt dann deutlich ab. Glücklicherweise sind heutzutage die allermeisten Abstoßungen gut behandelbar und führen nicht zu einem Verlust des Organs. Die endgültige Diagnose wird durch eine Transplantatbiopsie gestellt werden. Die meisten Abstoßungen werden mit einem intravenösen Urbasonstoß (= spezielles Kortisonpräparat) behandelt und sind damit gut in den Griff zu bekommen. Eventuell werden Ihre Medikamente nach einer Abstoßung umgestellt. 
                <br><br>
                Bei folgenden Symptomen ist das Transplantationszentrum sofort zu informieren. Nur so kann eine schnelle Diagnostik und Therapie eingeleitet werden, um das Transplantat in seiner Funktion zu erhalten:
                <br><br>
                -	Fieber über 38°C, Müdigkeit, Schwäche<br>
                -	Deutlich weniger Urinausscheidung<br>
                -	Gewichtszunahme, Schwellung der Beine<br>
                -	Schmerzen im Transplantatbereich<br>
                -	Vergrößerung der Niere<br>
                -	Blutdruckanstieg
                <br><br>
                Um eine Abstoßungsreaktion zu vermeiden, nehmen sie die notwendigen Immunsuppressiva nach Vorschrift regelmäßig ein. Eine Änderung der Medikamenteneinnahme bzw. ein Austausch von Originalmedikamenten gegen ein anderes Medikament darf nur Ihr Arzt in der Transplant-Ambulanz vornehmen. Sie sollten auch keine zusätzlichen Medikamente ohne Rücksprache mit Ihrem Arzt einnehmen, da Wechselwirkungen unter den Medikamenten ebenfalls zu Blutspiegelverschiebungen bei den Immunsuppressiva führen können. Infektionen wie z. B. Husten, Schnupfen oder Nierenentzündungen, die unter Umständen eine Abstoßung auslösen, können Sie durch eine gesunde Ernährung und Lebensweise weitgehend vermeiden.
              </p>
            </b-card-body>
          </b-collapse>
          <div></div>
        </b-card>
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info">
              <p class="psemibold fontcolor-white">
                Infektionen
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Infektionen aller Art müssen Sie nach der Transplantation unbedingt ernst nehmen. Ihr Abwehrsystem kann durch die immunsuppressiven Medikamente Krankheitserregern gegenüber nicht mehr so reagieren, wie vor der Transplantation. 
                <br><br>
                Folgende Symptome müssen immer von einem Arzt beurteilt und ggf. therapiert werden. 
                <br><br>
                -	Brennen beim Wasserlassen<br>
                -	Fieber<br>
                -	anhaltender Husten<br>
                -	Atembeschwerden<br>
                -	Ausfluss und/oder Juckreiz im Intimbereich<br>
                -	schmerzhaft gereizte Schleimhäute von Mund, Hals, Auge und Nase 
                <br><br>
                Besonders im ersten Jahr nach der Transplantation ist Ihr Abwehrsystem durch die Immunsuppression stark beeinträchtigt. Vermeiden Sie Situationen mit erhöhter Ansteckungsgefahr, wie z.B. Fahrten in überfüllten öffentlichen Verkehrsmitteln, große Kaufhäuser (Sommer-, Winterschlussverkauf), Besuche von Massenveranstaltungen in geschlossenen Räumen, Sauna und öffentlichen Dampfbädern und Kontakt mit an Kinderkrankheiten erkrankten Kindern (z.B. Windpocken, Masern, Mumps). 
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info">
              <p class="psemibold fontcolor-white">
                Wundheilungsstörungen
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Durch die Immunsuppressiva kommt es zu einer verlängerten Wundheilungszeit. Wegen der geschwächten Körperabwehr sind Ihre Wunden zusätzlich durch mögliche bakterielle Infektionen gefährdet, weswegen auf Wundhygiene großer Wert  zu legen ist und der Wundverband regelmäßig gewechselt werden sollte. Um eine Nahtinsuffizienz (klaffende Stellen zwischen den Nahtklammern) zu verhindern, tragen Sie bitte bis zur vollständig abgeschlossenen Wundheilung einen Bauchgurt, den Sie von uns erhalten. Die genaue Tragdauer besprechen Sie nach Entlassung bitte mit Ihrem Arzt.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="info">
              <p class="psemibold fontcolor-white">
                Lymphozele
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Lymphozelen entstehen durch die operationstechnisch notwendige Durchtrennung von Lymphbahnen. Nach der Operation entleert sich die Lymphflüssigkeit aus den Lymphbahnen über die Drainagen. Normalerweise versiegt der Lymphfluss innerhalb weniger Tage. Bei einigen Patienten kommt es allerdings zu einem kontinuierlichen Austritt von Lymphe in den Bauchraum. Diese Lymphansammlung nennt man Lymphozele. Sollte eine Lymphozele zu groß werden oder auf die Niere, den Harnleiter oder die zuführenden Gefäße drücken, kann man sie mit Punktion und erneuter Drainageneinlage behandeln. In manchen Fällen ist auch eine erneute (wenn auch kleine) Operation notwendig.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-5 variant="info">
              <p class="psemibold fontcolor-white">
                Tumorerkrankung
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Das allgemeine Tumorrisiko ist durch die Immunsuppression auf das ca. dreifache erhöht. Weiterhin können die notwendigen Medikamente gegen Abstoßung zu Zuckerkrankheit (Diabetes mellitus) und Bluthochdruck (Hypertonie) führen, aber auch das Transplantat schädigen und damit zum langsamen Nierenversagen führen.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-6 variant="info">
              <p class="psemibold fontcolor-white">
                Bluthochdruck
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Die Nieren sind die Organe unseres Körpers, die den Blutdruck regeln. Der Blutdruck ist der Druck, der in den Schlagadern (Arterien) auf die Gefäßwände ausgeübt wird. Erhöhte Blutdruckwerte schädigen auf Dauer die Nieren, aber auch das Herz und das Gehirn. Deshalb können Dialysepflichtigkeit, Herzschwäche oder -infarkt und Schlaganfall Folge von Bluthochdruck sein. Ein richtig eingestellter Blutdruck ist daher wichtig für den Schutz der Nieren.
                <br><br>
                In der Regel wird Hochdruck mit einer Kombination aus Lebensumstellung und Medikamenten behandelt. Es gibt mehrere Arten von blutdrucksenkenden Medikamenten, die unterschiedlich wirken und meist kombiniert angewendet werden (Kombinationstherapie). Mit einer Umstellung des Lebensstils können Sie viel bewirken, um das eigene Risiko für Bluthochdruck zu senken. Hierzu kann gehören:
                <br><br>
                -	Mehr körperliche Bewegung<br>
                -	Verringerung von Übergewicht<br>
                -	Ernährung mit weniger Salz und Alkohol<br>
                -	Vermeidung von Stress<br>
                -	Nicht rauchen
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-7 variant="info">
              <p class="psemibold fontcolor-white">
                Zuckerkrankheit
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Einen nach einer Transplantation aufgetretenen Diabetes mellitus bezeichnet man als PTDM (Post-Transplant-Diabetes) oder NODAT (New-Onset-Diabetes after Transplantation). Ursachen für diese Form des Diabetes ist vor allem die langfristige Therapie mit Immunsuppressiva. Bei Auftreten dieser speziellen Form der Zuckerkrankheit können unterschiedliche Maßnahmen, etwa eine Anpassung der Medikamente, eine Umstellung der Ernährung und mehr Bewegung, ergriffen werden.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- <information-accordion accordiontitle="Vitalwerte" accordiontext="Loremipsumdolorsit"></information-accordion> -->
      </div>
    </div>
    <foot-navigation></foot-navigation>
  </div>
</template>

<script>
  import router from '../router';
  import FootNavigationVue from "../components/FootNavigation.vue";

  export default {
    components: {
      'foot-navigation' :FootNavigationVue,
    },
    methods: {
      informationernaehrungzuinformation() {
        router.push({ path: '/information' })
      },
    },
   }; 
</script>

<style>
.accordion {
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    text-align: left;
}

.accordion .card:first-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.mb-1, .my-1 {
    margin-bottom: 0.25rem!important;
}

.p-1 {
    padding: 0rem!important;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    border-radius: 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.card-header:first-child {
    border-radius: 0 !important;
}

.card-header {
    padding: 0.75rem 0rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.btn {
    background: transparent;
    border: 0;
    outline: none;
    border-radius: 30px;
    display: inline-block;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 1.3rem 0rem;
    font-size: 1rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-info {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show>.btn-info.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-info.focus, .btn-info:focus {
    box-shadow: none !important;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0rem 0rem 1.5rem 0rem;
}

</style>